<template>
  <div>
    <v-row >
      <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
        <v-card class="pa-7">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="main_red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('deleteQuestion') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
                <v-btn block depressed color="red" class="text-color-white" @click="deleteOrganizationConfirm">{{ $t('deleteField') }}</v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn  outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-data-table
              v-if="allMedicalInstitutions"
              class="ma-4 medical-staff-data-table"
              :loading="loading"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers="headers"
              :headers-length="headers.length"
              :search="appBarSearch"
              :items="allMedicalInstitutions"
              :items-per-page="-1"
              sort-by="id"
              item-key="id"
              fixed-header
              dense
              @click:row="medicalInstitutionDetails"
              :height="pageHeight(135)"
              :single-expand="true"
              :expanded.sync="expanded"
              :footer-props="{
							showCurrentPage: false,
							showFirstLastPage: false,
							itemsPerPageOptions: [allMedicalInstitutions.length],
							itemsPerPageText: null
						}"
          >
            <template v-slot:item="{ item }">
              <tr @click="medicalInstitutionDetailsV2(item)">
                <td >
                  <!--                Temporarily closed FIXME-->
                  <!--                <div class="align-end d-flex">-->
                  <!--                  <v-btn class="ml-n10 " v-if="isOpen[item.id]"  small icon :style="{ color: 'black' }">-->
                  <!--                    <v-icon class="mdi-36px" color="primary">mdi-message</v-icon>-->
                  <!--                  </v-btn>-->
                  <!--                </div>-->
                  <div class="align-center d-flex text-left">
                    <span class="staff-name align-center table-items-small">{{ item.institutionName }}</span>
                  </div>
                  <!--								<div class="table-cols table-items-small">-->
                  <!--								</div>-->
                </td>
                <td>
                  <div class="table-cols2 table-items-small text-left">
                    <span>{{ item.contactPerson }}</span>
                  </div>
                </td>
                <td>
                  <div class="table-cols2">
                    <v-icon small class="mr-2 color-primary">mdi-email</v-icon>
                    <span class="color-primary table-items-small">{{ item.contactEmail }}</span>
                  </div>
                </td>
                <td>
                  <div class="table-cols3">
                    <v-icon small class="mr-2 color-primary">mdi-phone</v-icon>
                    <span class="color-primary table-items-small">{{ item.contactPhone }}</span>
                  </div>
                </td>
                <td class="text-right">
                <v-icon small :style="1 === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
                  <v-menu
                      left
                      bottom
                      class="py-0"
                      offset-y
                      transition="slide-x-transition"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                          icon
                          color="main_black"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <span class="mdi mdi-24px mdi-dots-horizontal"></span>
                      </v-btn>
                    </template >

                    <v-list class="px-0" dense >
                      <v-list-item class="py-0 my-0" link dense @click="handleActionView(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="main_black">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'"/>
                      <v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="generateCSV(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon class="icon-img mdi-18px" color="main_black">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">CSV</v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="roleName === 'SYSTEM_ADMIN'" link dense @click="deleteOrganizationById(item)">
                        <v-list-item-icon class="mx-1 ">
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="font-bold font-size14">{{$t("delete")}}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.statusShortDescription`]="{ item }">
              <img :src="patient_icon(item.status)" height="24px" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { organizationsWhite, mailPurple, phonePurple } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
  components: {
  },
  data: () => ({
    isOpen: {},
    loading: true,
    search: '',
    organizations_white: organizationsWhite,
    mailPurple: mailPurple,
    phonePurple: phonePurple,
    expanded: [],
    dialogDelete: false,
    organizationIdToDelete: -1,
  }),
  computed: {
    ...mapGetters({
      roleName: 'authentication/getRole',
      delay: 'authentication/getDelay',
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
    }),
    ...mapState({
      pageSize: (state) => state.index.pageSize,
      allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      generatedCsvFile: (state) => state.billings.generatedCsvFile,
    }),
    height () {
      return window.innerHeight - 250;
    },
    testHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return window.innerHeight - 320;
        case 'sm':
          return window.innerHeight - 300;
        case 'md':
          return window.innerHeight - 300;
        case 'lg':
          return window.innerHeight - 280;
        case 'xl':
          return window.innerHeight - 280;
        default:
          return 0;
      }
    },
    headers () {
      var headers = [];
      headers = [
        {
          text: this.$t('header-institute-name'),
          value: 'institutionName',
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-person'),
          value: 'contactPerson',
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-email'),
          value: 'contactEmail',
          class: this.headerClass,
        },
        {
          text: this.$t('header-contact-phone'),
          value: 'contactPhone',
          class: this.headerClass,
        },
        { text: '', value: 'status', class: this.headerClass, sortable: false },
      ];
      return headers;
    },
  },
  async mounted () {
    await this.getMedicalInstitutions();
  },
  methods: {
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            // this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async editMedicalInstitution (item) {
      await this.$store.commit('medicalInstitutions/SELECTED_MEDICAL_INSTITUTION', item); // get from the store, not from API call
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
    },
    medicalInstitutionDetails (item) {
      this.editMedicalInstitution(item);

      // Do not delete this commented code until delete medical inst is implemented

      // this.$router.push({
      //   name: "Medical Organization Details",
      //   params: { id: `${item.id}` },
      // });
    },
    medicalInstitutionDetailsV2 (item) {
      this.editMedicalInstitution(item);
      this.$set(this.isOpen, item.id, !this.isOpen[item.id]);
    },
    handleActionView (item) {
      this.editMedicalInstitution(item);
    },
    async generateCSV (item) {
      // headers: organizationId, month, year
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const data = { organizationId: item.id, month: currentMonth, year: currentYear };
      await this.$store.dispatch('billings/generateCsvOrganizationPatientsForMonth', data)
          .then(res => {
            showSuccessAlert(this.$t('success-operation'));

            const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'org-billings-csv.csv');
            document.body.appendChild(link);
            link.click();
          });
    },
    async deleteOrganizationById (item) {
      this.dialogDelete = true;
      this.organizationIdToDelete = item.id;
    },
    async deleteOrganizationConfirm () {
      await this.$store.dispatch('medicalInstitutions/deleteMedicalInstitution', this.organizationIdToDelete)
          .then(res => {
            if (res.data.resFlag) {
              showSuccessAlert(this.$t('success-operation'));

              this.getMedicalInstitutions(); // reload the list of organizations
              this.closeDeleteDialog();
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
          });
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.organizationIdToDelete = -1;
    },
  },
};
</script>

<style>
/* *{
	border: red solid 1px;
} */
.theme--light.v-data-table.v-data-table--fixed-header thead th{
  color:none !important;
}
.table-items-small{
  font-size:small;
}
.table-items-medium{
  font-size:medium;
}
.table-cols {
  display: flex;
  align-items: center;
  min-width: 150px;
}
.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.staff-name {
  font-weight: bold;
}

.role-icon{
  vertical-align: text-top;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px; /* or margin-left: 10px; */
}
.table-cols2{
  display: flex;
  align-items: center;
}
.table-cols3{
  display: flex;
  align-items: center;
}
.staff-office{
  align-items: center;
  min-width: 90px;
}
.staff-email{
  color: #6600FF;
  padding-left: 10px;
}
.staff-phone{
  color: #6600FF;
  padding-left: 0px;
  min-width: 110px;
}
.table-cols2 img,
.table-cols3 img {
  padding:  5px;
}
th{
  background-color:#ECEAF3 !important;
}
.color-header {
  background-color: #ECEAF3 !important;
}

.v-data-footer{
  justify-content: right;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-data-footer__icons-after{
  border: #e1dce8 solid 1px;
  border-radius: 5px;
  margin-right: 10px;
}

.v-data-footer__icons-after:hover{
  border: #bab0c9 solid 1px;
}

.v-data-footer__icons-before .v-btn{
  height: 30px;
  width: 30px;
  margin-right: 0px !important;
}

.v-data-footer__icons-before .v-btn .v-icon{
  font-size: 16px;
}

.v-data-footer__icons-after .v-btn{
  height: 30px;
  width: 30px;
  margin-left: 0px !important;
}

.v-data-footer__icons-after .v-btn .v-icon{
  font-size: 16px;
}
.icon-background {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #FFE4E6; /* Change this to the desired color */
  width: 50px; /* Adjust the width and height to create a circular shape */
  height: 50px;
}
</style>
